import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import Questionnaire from '../components/organisms/questionnaire'
import KeyFeatures from '../components/molecules/key-features'

import steps from "../data/pages/questionnaire/steps.json"
import verifyInformation from "../data/pages/questionnaire/verify.json"
import content from "../data/pages/questionnaire/content.json"

const QuestionnairePage = () => (
  <Layout showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    <Heading 
        title='"Descubra cómo esta propiedad en Miami puede generarle altos retornos de su inversión, ¡hasta un 14% o más! '
        subTitle=''
        weight='bold'
        color='light'
    />
    <Questionnaire 
      steps={steps}
      verifyInformation={verifyInformation}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      formType="wall-bed"
      thankYouChatBot
    />
    {/* <KeyFeatures
      features={[
        {
            title: 'Double Lifetime Warranty',
            image: 'icon-1.png',
            imageAlt: ''
        },
        {
            title: 'USA-Made<br/>Materials',
            image: 'icon-2.png',
            imageAlt: ''
        },
        {
            title: 'Installed In Less&nbsp;Than&nbsp;1&nbsp;Day',
            image: 'icon-3.png',
            imageAlt: ''
        }
    ]}
    /> */}
  </Layout>
)

export default QuestionnairePage
